<template>
  <section id="hero">
    <v-sheet
      dark
      tile
    >
      <v-img
        :src="require('@/assets/Main/birding/wal (2).webp')"
        :height="$vuetify.breakpoint.xsOnly ? '100vh' : '100vh'"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            />
          </v-row>
        </template>
        <v-container
          light
          fill-height
          fluid
          :px-12="$vuetify.breakpoint.mdAndUp"
        >
          <v-row
            align="center"
            justify="start"
          >
            <v-slide-x-reverse-transition appear>
              <v-col
                cols="12"
                md="7"
                ma="0"
              >
                <div>
                  <h1
                    :class="[
                      $vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline'
                    ]"
                    class="font-weight-bold"
                  >
                    Birding
                  </h1>

                  <v-divider class="mb-3" />

                  <div class="subheading mb-4">
                    <p>
                      <em>This beautiful untouched location makes for the perfect
                        adventuring.</em>
                    </p>
                  </div>
                  <v-row>
                    <v-row>
                      <v-btn
                        color="thirdley"
                        class="ml-3"
                        tile
                        outlined
                        @click="ShowMenuClicked = true"
                      >
                        Bird Check List &nbsp;&nbsp;
                        <span style="padding-left: 3px; font-size: 16px;">
                          <font-awesome-icon
                            right
                            :icon="['fas', 'book-open']"
                          />
                        </span>
                      </v-btn>
                      <v-btn
                        href="#content"
                        color="thirdley"
                        class="ml-3"
                        tile
                        outlined
                      >
                        Details&nbsp;
                        <v-icon>{{ svgArrow }}</v-icon>
                      </v-btn>
                    </v-row>
                    <v-overlay
                      absolute
                      :value="ShowMenuClicked"
                    >
                      <v-col cols="10">
                        <iframe
                          v-if="ShowMenuClicked"
                          src="/static/checklist.pdf"
                          :height="[
                            $vuetify.breakpoint.smAndUp ? '700' : '400'
                          ]"
                          :width="[$vuetify.breakpoint.smAndUp ? '900' : '250']"
                        />
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          icon
                          @click="ShowMenuClicked = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-overlay>
                  </v-row>
                </div>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
        </v-container>
      </v-img>
    </v-sheet>
  </section>
</template>

<style scoped>
#scroll_more {
  position: absolute;
  bottom: 12%;
  left: 50%;
  width: 100%;
  height: 6px;
  cursor: pointer;
}
</style>

<script>
  import {
    mdiGlassWine,
    mdiCompass,
    mdiFruitGrapes,
    mdiFruitGrapesOutline,
    mdiBottleWine,
    mdiFileDownload,
    mdiArrowDownBold
  } from '@mdi/js'

  export default {
    name: 'HomeHero',
    props: {
      gradient: {
        type: String,
        default: 'to top, rgba(0,0,0,0), rgba(0,0,0,0)'
      }
    },
    data: () => ({
      ShowMenuClicked: false,
      svgWine: mdiGlassWine,
      svgCompass: mdiCompass,
      svgGrape: mdiFruitGrapes,
      svgGrapeOut: mdiFruitGrapesOutline,
      svgBottle: mdiBottleWine,
      svgDown: mdiFileDownload,
      svgArrow: mdiArrowDownBold,

      sbsocials: [
        ['https://www.facebook.com/SushiBoxSA', 'mdi-facebook', 'Facebook'],
        ['https://twitter.com/SushiBoxSA', 'mdi-twitter', 'Twitter'],
        ['https://www.instagram.com/sushiboxsa/', 'mdi-instagram', 'Instagram']
      ]
    })
  }
</script>
